import { tcf } from '@schibsted/sourcepoint';

import type { SDKConfigInput } from '@spt-tracking/pulse-sdk';

import { frontendConfig } from '../../../src/core/system/config/frontend.server.js';

type TcfConfigOptions =
  | Pick<SDKConfigInput, 'consents' | 'requireAdvertisingOptIn'>
  | Record<string, never>;

export const getTcfConfig = (): TcfConfigOptions => {
  const consents =
    (typeof window === 'object' &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window._tcf_ as any)?.getCachedOrDefaultConsentsForPulse()) ||
    undefined;

  return consents
    ? {
        consents,
        requireAdvertisingOptIn: true,
      }
    : {};
};

export const initSourcepoint = (): void => {
  tcf(window, document, navigator, frontendConfig.sourcepoint);
};
