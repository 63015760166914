type PulseAttributes = {
  entityId?: string;
  entityName?: string;
  entityType?: string;
  elementPosition?: number;
  source?: string;
  elementSize?: string;
  entityTargetId?: string;
  entityTargetName?: string;
  entityTargetUrl?: string;
  elementPositionInBundle?: number;
  entityAccessLevel?: string;
  entityAccessLevelName?: string;
  entityImageUrl?: string;
  entitySize?: { height: number; width: number };
  elementBundleId?: string;
};

function getAttributeNumber(
  element: Element,
  attributeName: string,
): number | undefined {
  return element.hasAttribute(attributeName)
    ? Number(element.getAttribute(attributeName))
    : undefined;
}

const getPulseAttributesFromElement = (element: Element): PulseAttributes => {
  return {
    entityId: element.getAttribute('data-pulse-entity-id') || undefined,
    entityName: element.getAttribute('data-pulse-entity-name') || undefined,
    entityType: element.getAttribute('data-pulse-entity-type') || undefined,
    elementPosition: getAttributeNumber(element, 'data-pulse-position'),
    source: element.getAttribute('data-pulse-source') || undefined,
    elementSize: element.getAttribute('data-pulse-element-size') || undefined,
    entityTargetId:
      element.getAttribute('data-pulse-entity-target-id') || undefined,
    entityTargetName:
      element.getAttribute('data-pulse-entity-target-name') || undefined,
    entityTargetUrl:
      element.getAttribute('data-pulse-entity-target-url') || undefined,
    elementPositionInBundle: getAttributeNumber(
      element,
      'data-pulse-position-in-bundle',
    ),
    entityAccessLevel:
      element.getAttribute('data-pulse-access-level') || undefined,
    entityAccessLevelName:
      element.getAttribute('data-pulse-access-level-name') || undefined,
    entityImageUrl:
      element.querySelector('img')?.getAttribute('src') || undefined,
    entitySize: { height: element.clientHeight, width: element.clientWidth },
    elementBundleId: element.getAttribute('data-pulse-bundle-id') || undefined,
  };
};

export { getPulseAttributesFromElement, type PulseAttributes };
