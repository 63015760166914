import type { DataObject, UserDataStoreApi } from './types.js';

import { fasten } from '../frontend-api.js';

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type DataObjects = Array<DataObject<any>>;

const API_URL = '/api/user/data/';
const TTL_IN_MILLISECONDS = 20 * 60 * 1000;

let dataObjects: DataObjects = [];
let expirationTimestamp = 0;

const getSingleDataObject = <T>(key: string): DataObject<T> | undefined => {
  return dataObjects.find((item) => item.key === key);
};

const isCacheEmpty = (): boolean => {
  return !dataObjects.length && !expirationTimestamp;
};

const isCacheExpired = (): boolean => {
  return !!expirationTimestamp && expirationTimestamp < Date.now();
};

const invalidateCache = (): void => {
  dataObjects = [];
  expirationTimestamp = 0;
};

const fetchDataObjects = async (): Promise<void> => {
  dataObjects = await fasten.fetch<DataObjects>('GET', API_URL);
};

export const userDataStoreApi: UserDataStoreApi = {
  getUserData: async <T>(key: string): Promise<DataObject<T> | undefined> => {
    if (isCacheEmpty() || isCacheExpired()) {
      await fetchDataObjects();
      expirationTimestamp = Date.now() + TTL_IN_MILLISECONDS;
    }

    return getSingleDataObject<T>(key);
  },
  setUserData: async (key: string, value: unknown): Promise<void> => {
    await fasten.fetch(
      'post',
      API_URL,
      { 'Content-Type': 'application/json' },
      'json',
      false,
      JSON.stringify({ key, value }),
    );

    invalidateCache();
  },
  deleteUserData: async (key: string): Promise<{ success: boolean }> => {
    const response = await fasten.fetch(
      'delete',
      API_URL,
      { 'Content-Type': 'application/json' },
      'json',
      false,
      JSON.stringify({ key }),
    );

    invalidateCache();

    return response as { success: boolean };
  },
};
