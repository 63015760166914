const AUTHENTICATED_PATH =
  '/privacy/v2/schacc/{publication}/user/sdrn:spid.no:user:{userId}/permissions';
const ANONYMOUS_PATH =
  '/privacy/v2/anonymous/{publication}/user/sdrn:schibsted:environment:{environmentId}/permissions';

export type UserConsent = {
  purpose: string;
  data: string[];
};

export type UserConsents = UserConsent[];

type UserConsentsApiResponse = {
  permissions: UserConsents;
};

export const fetchAnonymousUserConsents = async (
  environmentId: string,
  publication: string,
  apiUrl: string,
): Promise<UserConsentsApiResponse> => {
  const path = ANONYMOUS_PATH.replace('{environmentId}', environmentId).replace(
    '{publication}',
    publication,
  );
  const { fasten } = await import('./frontend-api.js');

  return fasten.fetch('GET', `${apiUrl}${path}`);
};

export const fetchAuthenticatedUserConsents = async (
  userId: number,
  userToken: string,
  publication: string,
  apiUrl: string,
): Promise<UserConsentsApiResponse> => {
  const path = AUTHENTICATED_PATH.replace('{userId}', String(userId)).replace(
    '{publication}',
    publication,
  );
  const { fasten } = await import('./frontend-api.js');

  return fasten.fetch('GET', `${apiUrl}${path}`, {
    Authorization: `SPIDSIG ${userToken}`,
  });
};
