export const isLocalhost = (): boolean => {
  const hostname = window.location.hostname;

  return (
    hostname.includes('localhost') ||
    hostname.includes('127.0.0.1') ||
    hostname.startsWith('local.')
  );
};

export const isReviewApp = (): boolean =>
  window.location.hostname.includes('pr.sls.schibsted.tech');
