import type { BlendPersonalization } from '../../../data-sources/frapi/types.js';

export const generateMetrics = (
  variant: string | undefined,
  personalization: BlendPersonalization | undefined | null,
) => {
  if (!personalization || !variant) return [];

  return [
    {
      source: 'Curate',
      name: 'personalizedResults',
      key: variant,
      value: personalization.personalizedResults,
    },
    {
      source: 'Curate',
      name: 'removeImpressed',
      key: variant,
      value: personalization.removeOnImpressed,
    },
    {
      source: 'Curate',
      name: 'removeRead',
      key: variant,
      value: personalization.removeOnRead,
    },
  ];
};
