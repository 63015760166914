/* eslint-disable prefer-rest-params */
export const createEmbedScript = (
  w: Window,
  d: Document,
  n: string,
  t: string,
  s: string,
): void => {
  w[n] =
    w[n] ||
    function () {
      (window[n].q = window[n].q || []).push(arguments);
    };
  const a = d.createElement(t);
  const b = document.getElementsByTagName(t)[0];
  a.setAttribute('async', '');
  a.setAttribute('src', s);
  b.parentNode.insertBefore(a, b);
};
