import { datadogLogs } from '@datadog/browser-logs';

import { frontendConfig } from '../../src/core/system/config/frontend.server.js';

export function initializeBrowserLogs(
  options: Partial<Parameters<typeof datadogLogs.init>[0]> = {},
): void {
  const {
    publication,
    serverRuntime,
    browserLogs: { clientToken },
  } = frontendConfig;

  if (!clientToken) {
    return;
  }

  datadogLogs.init({
    clientToken,
    site: 'datadoghq.eu',
    forwardErrorsToLogs: true,
    sessionSampleRate: 1,
    service: publication,
    env: serverRuntime,
    ...options,
  });
}
