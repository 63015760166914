import { prepReferringImpression } from './pulse-util.js';

// Used in snt/widget-list app:
// https://github.schibsted.io/snt/widget-list/blob/4b33f7e093a6b9d5eff18c824bcf32167b6e3c13/js/tracking.js
export const initWidgetTracking = (): void => {
  window.addWidgetTracking = function (scope) {
    if (
      typeof prepReferringImpression === 'function' &&
      typeof window.trackClick === 'function'
    ) {
      document
        .querySelectorAll(`${scope} [data-pulse-entity-id]`)
        .forEach(function (el: HTMLElement) {
          el.addEventListener('click', async function () {
            await prepReferringImpression(el);
            window.trackClick(el);
          });
        });
    }
  };
};
