import type { Tracker as PulseTracker } from '@spt-tracking/pulse-sdk';

import {
  isLocalhost,
  isReviewApp,
} from '../../../../../public-src/core/js/utils/is-localhost.js';

const handleDebugMode = (pulseTracker: PulseTracker): void | null => {
  if (!pulseTracker) {
    return null;
  }

  if (isLocalhost()) {
    pulseTracker.update({
      deployTag: 'fasten-dev',
    });
  }

  if (isReviewApp()) {
    pulseTracker.update({
      deployTag: 'fasten-pr',
    });
  }
};

export { handleDebugMode };
