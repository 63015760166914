import type { Actor } from '@schibsted/pulse-news-media';

import { getSessionData } from '../../../../../public-src/core/js/schibsted-account/index.server.js';

const WITHOUT_SUBSCRIPTION = 'No';
const ABO_SUBSCRIPTION = 'Abo';
const REALM = 'spid.no';

const getActorPromise = async (): Promise<Actor> => {
  const sessionData = await getSessionData();

  if (!sessionData) {
    return { id: undefined };
  }

  const {
    user: { userId },
    access: { entitled },
  } = sessionData;

  const subscriptionName = entitled ? ABO_SUBSCRIPTION : WITHOUT_SUBSCRIPTION;

  return {
    id: userId,
    subscriptionName,
    realm: REALM,
  };
};

export { getActorPromise };
