import {
  isLocalStorageSupported,
  saveItemToLocalStorage,
  getItemFromLocalStorage,
} from './local-storage.js';

const LOCAL_STORAGE_KEY = 'persisted-page-reload-time';
const MINIMAL_RELOAD_INTERVAL_MINUTES = 3;

const storeLastReloadTime = (now: number): void => {
  saveItemToLocalStorage(LOCAL_STORAGE_KEY, now.toString());
};

const getLastReloadTime = (): number => {
  const lastReloadStored = getItemFromLocalStorage(LOCAL_STORAGE_KEY);

  if (!lastReloadStored) {
    return 0;
  }

  return Number.parseInt(lastReloadStored, 10) || 0;
};

export function reloadPersistedPageAfterMinutes(minutes: number): void {
  reloadPersistedPageAfter({ minutes });
}

export type ReloadPersistedPageAfterArgs = {
  minutes: number;
  reloadFn?: () => void;
};

export function reloadPersistedPageAfter({
  minutes,
  reloadFn = () => {
    window.location.reload();
  },
}: ReloadPersistedPageAfterArgs): () => void {
  if (!isLocalStorageSupported) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }

  const pageLoadedTime = new Date().getTime();

  function checkPersisted(event: PageTransitionEvent): void {
    if (event.persisted && pageLoadedTime) {
      const now = new Date().getTime();

      const minutesSincePageLoad = (now - pageLoadedTime) / 1000 / 60;
      const minutesSinceLastReload = (now - getLastReloadTime()) / 1000 / 60;

      if (
        minutesSinceLastReload > MINIMAL_RELOAD_INTERVAL_MINUTES &&
        minutesSincePageLoad > minutes
      ) {
        storeLastReloadTime(now);
        reloadFn();
      }
    }
  }

  window.addEventListener('pageshow', checkPersisted);

  return () => {
    window.removeEventListener('pageshow', checkPersisted);
  };
}
