import type { Tracker as PulseTracker } from '@spt-tracking/pulse-sdk';
import type { PulseContextProviderConfig } from '../types/index.js';

const handleProductTag = (
  pulseTracker: PulseTracker,
  config: PulseContextProviderConfig,
): void => {
  const { productTag, providerId } = config;

  if (pulseTracker) {
    pulseTracker.update({
      provider: {
        id: providerId,
        productTag: productTag ? productTag : undefined,
        product: 'fastenposten',
        productType: 'ResponsiveWeb',
      },
    });
  }
};

export { handleProductTag };
