import {
  getSessionData,
  getLoginUrl,
  loginAction,
  logoutAction,
} from './index.server.js';

const createCoreHeaderSessionAdapter = () => {
  return {
    id: 'fastenSession', // used just for the identification
    getSessionData: async () => {
      const session = await getSessionData();

      if (!session) {
        return {
          user: null,
          hasProduct: false,
          loginURI: getLoginUrl(),
        };
      }

      return {
        user: session?.user,
        hasProduct: session.access.entitled,
      };
    },
    onLoginClick: loginAction,
    onLogoutClick: logoutAction,
  };
};

export const provideCoreHeaderSessionAdapter = () => {
  if (typeof window === 'undefined') {
    return;
  }

  window.__CORE_HEADER_CONFIG__ = window.__CORE_HEADER_CONFIG__ || {};
  window.__CORE_HEADER_CONFIG__.sessionAdapter =
    createCoreHeaderSessionAdapter();
};
