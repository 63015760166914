export function resolveOnce<T>(resolveValue: () => T): () => T {
  let value: T;

  return function (): T {
    if (!value) {
      value = resolveValue();
    }

    return value;
  };
}
