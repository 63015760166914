const script = `window.iterateSettings = {
  apiKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb21wYW55X2lkIjoiNjA5NGVhMjQxNmQ1YjUwMDAxNWM2NDdlIiwiaWF0IjoxNjIwMzcyMDA0fQ.7akDdCSrVHjut8I1RaRJTh2JJPoNYND7vneKrLqwJxk'
};

(function(i,t,e,r,a){if(t.getElementById(r)){return}
i.IterateObjectName=a;var z=function(){z.c(arguments)};z.q=[];
z.c=function(args){z.q.push(args)};i[a]=z;
function l() {var js,fjs=t.getElementsByTagName(e)[0];js=t.createElement(e);js.id=r;js.async=1;js.src="https://platform.iteratehq.com/loader.js";fjs.parentNode.insertBefore(js,fjs)};
if(t.readyState==="complete") {l();} else if(i.attachEvent) {i.attachEvent('onload', l);} else{i.addEventListener('load', l, false);}
}(window, document,'script','iterate-js','Iterate'));`;

export const initIterate = () => {
  const tag = document.createElement('script');
  tag.innerHTML = script;

  const head = document.querySelector('head');
  head?.insertAdjacentElement('beforeend', tag);
};
