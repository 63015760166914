import type { PulseConfigOptions } from './pulse-v4.js';

import { frontendConfig } from '../../../../src/core/system/config/frontend.server.js';
import { initPulseEngageAddon } from './pulse-addon-engage.js';
import { initPulseImpressionAddon } from './pulse-addon-impression.js';
import { loadPulse } from './pulse-load.js';
import { curateData } from './pulse-util.js';
import { pulseConfig } from './pulse-v4.js';
import { initWidgetTracking } from './widget-tracking.js';

async function initPulse(
  extendedPulseConfig: PulseConfigOptions,
): Promise<void> {
  const { providerId } = frontendConfig.pulseConfig;

  loadPulse();
  initWidgetTracking();
  initPulseEngageAddon();
  initPulseImpressionAddon();
  await pulseConfig(providerId, extendedPulseConfig);
  curateData(extendedPulseConfig);
}

export { initPulse };
